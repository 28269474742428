import React from 'react';

import DescriptionEditorPanel from 'client/app/apps/protocols/annotations/DescriptionEditorPanel';
import DisplayDescription from 'client/app/apps/protocols/annotations/DisplayDescription';
import { useAdditionalPanelContext } from 'common/ui/providers/AdditionalPanelProvider';

type Props = {
  description: string;
  onSave: (description: string) => void;
};

/**
 * Shows the description of something that on click opens an editor panel and saves the changes
 */
export default function EditableDisplayDescription({ description, onSave }: Props) {
  const { setAdditionalPanel, clearAdditionalPanel } = useAdditionalPanelContext();

  const handleEditing = () => {
    setAdditionalPanel({
      contents: (
        <DescriptionEditorPanel
          description={description}
          onChange={onSave}
          onClose={clearAdditionalPanel}
        />
      ),
    });
  };

  return (
    <div onClick={handleEditing}>
      <DisplayDescription
        description={description}
        placeholder="+ Add description"
        sxContainer={{
          border: '1.5px solid transparent',
          '&.Mui-focused, &:hover': theme => ({
            border: `1.5px solid ${theme.palette.primary.main}`,
            borderRadius: '4px',
          }),
        }}
      />
    </div>
  );
}
