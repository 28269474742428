import React from 'react';

import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import MarkdownEditor from 'client/app/components/Markdown/MarkdownEditor';
import Colors from 'common/ui/Colors';
import Button from 'common/ui/components/Button';
import useDebounce from 'common/ui/hooks/useDebounce';

type Props = {
  description: string;
  onChange: (newDescription: string) => void;
  onClose: () => void;
};

export default function DescriptionEditorPanel({
  description,
  onChange,
  onClose,
}: Props) {
  const debouncedSave = useDebounce(onChange, 500);
  return (
    <Grow in unmountOnExit>
      <Panel elevation={4}>
        <TitleBar>
          <Typography variant="h5">Edit description</Typography>
          <Button size="small" onClick={onClose} variant="tertiary" color="primary">
            Done
          </Button>
        </TitleBar>
        <MainContainer>
          <MarkdownEditor initialContent={description} onChange={debouncedSave} />
        </MainContainer>
      </Panel>
    </Grow>
  );
}

const Panel = styled(Paper)({
  display: 'grid',
  gridTemplate: `
    'titlebar' auto
    'main' minmax(0, 1fr)
  `,
  width: '100%',
  height: '100%',
  overflow: 'auto',
  backgroundColor: Colors.WHITE,
  borderRadius: '8px',
});

const TitleBar = styled('div')(({ theme }) => ({
  gridArea: 'titlebar',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(0, 3),
  borderBottom: `1px solid ${theme.palette.grey[200]}`,
  height: '50px',
}));

const MainContainer = styled('div')({
  gridArea: 'main',
  display: 'flex',
  justifyContent: 'center',
  padding: 10,
});
