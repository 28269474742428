import React from 'react';

import Stack from '@mui/material/Stack';
import { SxProps, Theme } from '@mui/material/styles';

import Colors from 'common/ui/Colors';
import { MessagePreview, sxSmallerHeadings } from 'common/ui/components/MessagePreview';

type Props = {
  description?: string;
  placeholder?: string;
  sxContainer?: SxProps<Theme>;
};

export default function DisplayDescription({
  description,
  placeholder,
  sxContainer,
}: Props) {
  const message = description || placeholder;
  if (!message) {
    return null;
  }

  const isPlaceholder = message === placeholder;

  return (
    <Stack paddingInline={2} sx={sxContainer}>
      <MessagePreview
        message={message}
        messageType={isPlaceholder ? 'text' : 'markdown'}
        sx={isPlaceholder ? { color: Colors.GREY_50 } : sxSmallerHeadings}
      />
    </Stack>
  );
}
